export class JwtContainer {
    loginId: string;
    displayName: string;
    companyId: number;
    email: string;
    userId: number;
    roles: string[];
    isExternalCompany: boolean;

    constructor(loginId: string, displayName: string, email: string, userId: number,
                roles: string, companyId: string, isExternalCompany: string) {
        this.loginId = loginId;
        this.displayName = displayName;
        this.companyId = Number(companyId);
        this.email = email;
        this.userId = Number(userId);
        this.roles = JwtContainer.assignRoles(roles);
        this.isExternalCompany = isExternalCompany !== 'False';
    }

    private static assignRoles(rolesString: string): string[] {
        return rolesString.split(',');
    }
}
