import {Inject, Injectable} from '@angular/core';
import {WINDOW} from './window.service';
import {FileService} from './file.service';
import {FileMeta} from '../models/file-meta';
import {FileType} from '../models/enums/file-type';

@Injectable({
    providedIn: 'any'
})

export class FileViewerUtilService {

    constructor(
        private fileService: FileService,
        @Inject(WINDOW) private window: Window
    ) {
    }

    static getExtension(filename: string): string {
        let ext: string;
        const re = /(?:\.([^.]+))?$/;
        ext = re.exec(filename)[1];
        return ext ? ext : 'none';
    }

    getFile(fileMeta: FileMeta, fileType: FileType) {
        this.fileService.selectedFileForViewer$.next(fileMeta);
        this.fileService.getBytes(fileMeta.id, fileType)
            .subscribe(bytesInBase64 => {
                this.fileService.currentFileInViewerBytes$.next(this.stringToBytes(bytesInBase64));
        });
    }

    stringToBytes(str: string) {
        const raw = window.atob(str);
        const length = raw.length;
        let bytes = new Uint8Array(length);
        for (let i = 0; i < length; ++i) {
            bytes[i] = raw.charCodeAt(i);
        }
        return bytes;
    }

    transformToFiles(id: string, fileNm: string, mimeType: string, size: number): FileMeta {
        let file: FileMeta;
        const arr = new Uint8Array(size);
        const tempBlob: any = new Blob([arr], {type: mimeType});
        tempBlob.name = fileNm;
        file = tempBlob as FileMeta
        file.id = id;
        return file;
    }
}
