import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from './services/auth.service';
import {catchError, filter, finalize, switchMap, take, tap} from 'rxjs/operators';
import {SpinnerService} from './services/spinner.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class Interceptor implements HttpInterceptor {
    private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private isRefreshingToken = false;

    constructor(
        private authService: AuthService,
        private spinnerService: SpinnerService,
        private toastr: ToastrService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.show();

        if (req.url.endsWith('/login') ||
            req.url.endsWith('/refreshtoken') &&
            req.body.toString().indexOf('grant_type=refresh_token') > -1) {
            return next.handle(req).pipe(
                tap((event: HttpEvent<any>) => {
                    this.spinnerService.hide();
                })
            );
        } else {
            req = this.addToken(req);

            return next.handle(req).pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                        switch ((error as HttpErrorResponse).status) {
                            case 400:
                                return this.handle400Error(error);
                            case 401:
                                return this.handle401Error(req, next);
                            default:
                                return this.handleDefaultError(error);
                        }
                    } else {
                        console.log('intercept, other error occurred');
                        this.spinnerService.hide();
                        return throwError(error);
                    }
                }),
                finalize(() => {
                    this.spinnerService.hide();
                })
            );
        }
    }

    handle400Error(error: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (error?.error?.message) {
            this.toastr.error(error.error.message);
        } else if (error?.error) {
            this.toastr.error(error.error);
        }

        this.spinnerService.hide();
        if (this.authService.isLoggedIn && error && error.status === 400 && !error.error) {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }
        return throwError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.hide();
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((authResponse) => {
                    if (authResponse && authResponse.accessToken.token) {
                        this.tokenSubject.next(authResponse.accessToken.token);
                        return next.handle(this.addToken(req));
                    }
                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(error =>
                    // logout if 401 is thrown.
                {
                    if (error instanceof HttpErrorResponse && (error as HttpErrorResponse).status === 401) {
                        return this.logoutUser();
                    }
                    return this.handleDefaultError(error);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(() => next.handle(this.addToken(req))));
        }
    }

    handleDefaultError(error: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (error?.error?.message) {
            this.toastr.error(error.error.message);
        } else if (error?.error?.Message) {
            this.toastr.error(error.error.Message + ' Please contact our support team so we can try to fix it.');
        } else if (error?.error) {
            this.toastr.error('Error');
            this.toastr.error(error.error);
        }

        this.spinnerService.hide();
        return throwError(error);
    }

    logoutUser(): Observable<any> {
        return this.authService.logout(true);
    }

    private addToken(req: HttpRequest<any>): HttpRequest<any> {
        const authData = this.authService.getAuthData();
        if (authData) {
            return req.clone({setHeaders: {authorization: 'Bearer ' + authData.accessToken.token}});
        } else {
            return req;
        }
    }
}
