import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Util} from '../../classes/util';

export enum DualListTypes {
	assigned = 1,
	unassigned = 2
}

export class AssignUnassignConfirmationDialogData {
	type: string;
	username: string;
	userLoginId: number;
	assignedList: any[];
	unassignedList: any[];

	constructor(type: string, username: string, userLoginId: number, assignedList: any[], unassignedList: any[]) {
		this.type = type;
		this.username = username;
		this.userLoginId = userLoginId;
		this.assignedList = assignedList;
		this.unassignedList = unassignedList;
	}
}

@Component({
	selector: 'app-assign-subsidiary-confirmation-dialog',
	templateUrl: './assign-unassign-confirmation-dialog.component.html',
	styleUrls: ['./assign-unassign-confirmation-dialog.component.scss']
})
export class AssignUnassignConfirmationDialogComponent implements OnInit {
	selectedUnassignedOptions: any[] = [];
	selectedAssignedOptions: any[] = [];

	constructor(public dialog: MatDialogRef<AssignUnassignConfirmationDialogData>,
				@Inject(MAT_DIALOG_DATA) public data: AssignUnassignConfirmationDialogData) {
	}

	ngOnInit() {
	}

	onNoClick(): void {
		this.dialog.close();
	}

	moveAllRows(list: DualListTypes) {
		if (list === DualListTypes.unassigned) {
			this.data.assignedList = Util.sortList(this.data.assignedList.concat(this.data.unassignedList));
			this.data.unassignedList = [];
		} else if (list === DualListTypes.assigned) {
			this.data.unassignedList = Util.sortList(this.data.unassignedList.concat(this.data.assignedList));
			this.data.assignedList = [];
		}
        this.data.assignedList.forEach(item => { if (item.selected) { item.selected = undefined; } } );
        this.data.unassignedList.forEach(item => { if (item.selected) { item.selected = undefined; } } );
        this.selectedUnassignedOptions = [];
        this.selectedAssignedOptions = [];
	}

	moveOneRow(list: DualListTypes) {
		if (list === DualListTypes.unassigned) {
			this.selectedUnassignedOptions.forEach(role => {
				this.data.assignedList.push(role);
				role.selected = undefined;
				this.data.unassignedList.splice(this.data.unassignedList.findIndex(x => x === role), 1);
			});
			this.data.assignedList = Util.sortList(this.data.assignedList);
			this.selectedUnassignedOptions = [];
		} else if (list === DualListTypes.assigned) {
			this.selectedAssignedOptions.forEach(role => {
				this.data.unassignedList.push(role);
				role.selected = undefined;
				this.data.assignedList.splice(this.data.assignedList.findIndex(x => x === role), 1);
			});
			this.selectedAssignedOptions = [];
			this.data.unassignedList = Util.sortList(this.data.unassignedList);
		}
	}

	updateSelectedItems(role: any, list: DualListTypes) {
		if (list === DualListTypes.unassigned) {
			this.selectedUnassignedOptions.includes(role) ?
				this.selectedUnassignedOptions.splice(this.selectedUnassignedOptions.findIndex(x => x === role), 1)
				: this.selectedUnassignedOptions.push(role);
		} else if (list === DualListTypes.assigned) {
			this.selectedAssignedOptions.includes(role) ?
				this.selectedAssignedOptions.splice(this.selectedAssignedOptions.findIndex(x => x === role), 1)
				: this.selectedAssignedOptions.push(role);
		}
		role.selected = !role.selected;
	}
}
