<div mat-dialog-title *ngIf="data.title">
  <h3 [innerHTML]="data.title">
  </h3>
  <span class="dialog-close" [hidden]="data.hideTopCancelBtn" (click)="onNoClick()">&#10006;</span>
</div>
<div mat-dialog-content *ngIf="data.content">
  <p class="body-text" [innerHTML]="data.content"></p>
</div>
<div mat-dialog-actions class="dialog-action">
  <button mat-button (click)="onNoClick()">{{data.noBtnText}}</button>
  <button mat-flat-button [mat-dialog-close]="true" cdkFocusInitial color="accent">{{data.okBtnText}}</button>
</div>
