import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class ConfirmationDialogData {
  okBtnText: string;
  noBtnText: string;
  content: string;
  title: string;
  hideTopCancelBtn: boolean;

  constructor(title: string, content: string, okBtnText: string, noBtnText = 'Cancel', hideTopCancelBtn = false) {
    this.title = title;
    this.content = content;
    this.okBtnText = okBtnText;
    this.noBtnText = noBtnText;
    this.hideTopCancelBtn = hideTopCancelBtn;
  }
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
