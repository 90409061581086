import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {MaterialModule} from '../material.module';
import {MatToolTipShowIfTruncatedDirective} from './directives/mat-tooltip-show-if-truncated.directive.ts.directive';
import {AssignUnassignConfirmationDialogComponent} from './components/assign-unassign-confirmation-dialog/assign-unassign-confirmation-dialog.component';
import { FilesComponent } from './components/files/files.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';

@NgModule({
    declarations: [NotFoundComponent, ConfirmationDialogComponent, MatToolTipShowIfTruncatedDirective, AssignUnassignConfirmationDialogComponent, FilesComponent, FileViewerComponent],
    imports: [
        CommonModule,
        MaterialModule,
        PdfJsViewerModule
    ],
    exports: [
        MatToolTipShowIfTruncatedDirective,
        FileViewerComponent,
        FilesComponent
    ]
})
export class SharedModule {
}
