import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AuthData} from '../models/interfaces/auth-data';
import {config} from '../app.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {mergeMap, tap} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {JwtContainer} from '../models/jwt-container.model';
import {JwtTempContainer} from '../models/jwt-temp-container.model';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {LoginRequest} from '../models/requests/login-request';
import {RefreshTokenRequest} from '../models/interfaces/refresh-token-request';
import {MatDialog} from '@angular/material/dialog';

const httpOptions = {
    headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded'
    })
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isFullScreenMode$ = new BehaviorSubject<boolean | null>(null);
    authData$ = new BehaviorSubject<AuthData | null>(null);
    jwtContainer$ = new BehaviorSubject<JwtContainer | undefined>(undefined);
    companyKey$ = new BehaviorSubject<string | null>(null);
    companyId$ = new BehaviorSubject<number | null>(null);
    jwt: JwtContainer | undefined;
    hasToDos$ = new BehaviorSubject<boolean>(false);
    private jwtHelper = new JwtHelperService();
    private storageAuthData: AuthData | null;


    constructor(private http: HttpClient,
                private storageService: StorageService,
                private router: Router,
                private toastr: ToastrService,
                private dialog: MatDialog) {
        const authData = this.storageService.getItem('authData$');
        this.storageAuthData = authData != null
            ? JSON.parse(authData) as AuthData
            : null;

        if (this.storageAuthData) {
            this.setAuthorizationData(this.storageAuthData);
        }

        this.jwtContainer$
            .subscribe(jwt => {
                this.jwt = jwt;
                if (jwt) {
                    this.companyId$.next(jwt.companyId);
                } else {
                    this.companyKey$.next(null);
                }
            });
    }

    login(userName: string, password: string): Observable<AuthData> {
        const loginData = {
            userName,
            password
        } as LoginRequest;
        return this.http.post<AuthData>(config.authorizationApiUrl + 'login', loginData)
            .pipe(tap(authData => this.setAuthorizationData(authData)));
    }

    logout(showMessage: boolean = false): Observable<boolean> {
        this.storageAuthData = null;
        this.hasToDos$.next(false);
        this.storageService.removeItem('authData$');
        this.storageService.removeItem('pendingInviteDisplayed$');
        this.authData$.next(null);
        this.jwtContainer$.next(undefined);
        this.router.navigate(['']);
        this.dialog.closeAll();
        if (showMessage) {
            this.toastr.info('Please login to continue', 'Your session has expired.');
        }
        return of(true);
    }

    refreshToken(): Observable<AuthData | null> {
        if (this.storageAuthData !== null) {
            const refreshTokenRequest = {
                accessToken: this.storageAuthData.accessToken.token,
                refreshToken: this.storageAuthData.refreshToken
            } as RefreshTokenRequest;

            return this.http.post<AuthData>(config.authorizationApiUrl + 'refreshtoken', refreshTokenRequest)
                .pipe(tap(reAuthData => this.setAuthorizationData(reAuthData)));
        }

        return of(null);
    }

    setAuthorizationData(authData: AuthData): void {
        this.storageAuthData = authData;
        const tokenEnc = this.jwtHelper.decodeToken(authData.accessToken.token);
        const token = tokenEnc as JwtTempContainer;
        const jwtContainer = new JwtContainer(
            token.LoginId,
            token.DisplayName,
            token.Email,
            token.UserId,
            token.Roles,
            token.CompanyId,
            token.IsExternalCompany);

        this.authData$.next(authData);
        this.jwtContainer$.next(jwtContainer);

        const authorizationDataStorage = JSON.stringify(this.storageService.getItem('authData$'));
        if (authorizationDataStorage !== null) {
            this.storageService.removeItem('authData$');
        }

        this.storageService.addItem('authData$', JSON.stringify(authData));
    }

    isLoggedIn(): boolean {
        return !!this.jwt;
    }

    getAuthData(): AuthData | null {
        return this.storageAuthData;
    }

    httpRefresh(): Observable<AuthData> {
        return this.authData$.pipe(
            mergeMap(authData => {
                const data = 'grant_type=refresh_token&refresh_token=' +
                    authData?.refreshToken;
                return this.http.post<AuthData>(config.authorizationApiUrl + 'authToken', data, httpOptions);
            })
        );
    }
}
