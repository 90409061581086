import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})

export class RoleGuard implements CanActivate {
    roles = new Array<string>();

    constructor(public router: Router, private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const expectedRoles = route.data.expectedRoles as Array<string>;
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['']);
            return false;
        }

        this.authService.jwtContainer$.subscribe(x => x ? this.roles = x.roles : this.roles = []);
        for (const expected of expectedRoles) {
            for (const actual of this.roles) {
                if (expected === actual) {
                    return true;
                }
            }
        }

        this.router.navigate(['/auth/dashboard']);
        return false;
    }
}
