import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {WINDOW_PROVIDERS} from './services/window.service';
import {Interceptor} from './interceptor';
import {ToastrModule} from 'ngx-toastr';
import {SharedModule} from './shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        SharedModule,
        ToastrModule.forRoot({
            timeOut: 8000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            progressBar: true,
            enableHtml: true
        }),
        MaterialModule,
        NgIdleKeepaliveModule.forRoot()
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
