import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {config} from '../app.config';
import {GetSubmissionResponse} from '../models/interfaces/get-submission-response';
import {SubmissionDefinition} from '../models/enums/submission-defnition';
import {ReleaseSubmissionRequest} from '../models/requests/release-submission-request';
import {tap} from 'rxjs/operators';
import {UpdateIsReleaseReadyRequest} from '../models/requests/update-is-release-ready-request';
import {AddSubmissionInternalRequest} from '../models/requests/add-submission-internal-request';

@Injectable({
    providedIn: 'root'
})
export class SubmissionService {
    private readonly controller: string = 'Submission/';

    constructor(private http: HttpClient) {
    }

    getByLoginId(): Observable<GetSubmissionResponse[]> {
        return this.http.get<GetSubmissionResponse[]>(config.exclusiveApiUrl + this.controller + 'getByLoginId')
            .pipe(
                tap(response => response.map(s => s.submissionDefinition
                    = SubmissionDefinition[s.status as keyof typeof SubmissionDefinition]))
            );
    }

    get(id: string): Observable<GetSubmissionResponse> {
        return this.http.get<GetSubmissionResponse>(config.exclusiveApiUrl + this.controller + 'get?id=' + id)
            .pipe(
                tap(response => response.submissionDefinition = SubmissionDefinition[response.status as keyof typeof SubmissionDefinition])
            );
    }

    release(request: ReleaseSubmissionRequest): Observable<SubmissionDefinition> {
        return this.http.post<SubmissionDefinition>(config.exclusiveApiUrl + this.controller + 'release', request);
    }

    addInternal(request: AddSubmissionInternalRequest): Observable<GetSubmissionResponse> {
        return this.http.post<GetSubmissionResponse>(config.exclusiveApiUrl + this.controller + 'addInternal', request)
            .pipe(
                tap(response => response.submissionDefinition = SubmissionDefinition[response.status as keyof typeof SubmissionDefinition])
            );
    }

    updateIsReleaseReady(request: UpdateIsReleaseReadyRequest): Observable<any> {
        return this.http.patch(config.exclusiveApiUrl + this.controller + 'UpdateIsReleaseReady', request);
    }

    updateMoveToPendingAssignment(request: ReleaseSubmissionRequest): Observable<any> {
        return this.http.patch(config.exclusiveApiUrl + this.controller + 'isuploadingcomplete', request);
    }
}
