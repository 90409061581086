import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RoleGuard} from './guards/role.guard';
import {PublicRouteGuard} from './guards/public-route.guard';
import {RoleDefinition} from './models/enums/role-definition.enum';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./features/authenticated/authenticated.module').then(m => m.AuthenticatedModule),
        data: {isPublicRoute: false, expectedRoles: [RoleDefinition.BasicUser]},
        canActivate: [RoleGuard]
    },
    {
        path: '',
        loadChildren: () => import('./features/public/public.module').then(m => m.PublicModule),
        data: {isPublicRoute: true},
        canActivate: [PublicRouteGuard]
    },
    {
        path: '**',
        component: NotFoundComponent,
        pathMatch: 'full',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
