// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {CustomWindow} from '../app/models/interfaces/custom-window';

declare let window: CustomWindow;

export const environment = {
    production: window.env.production,
    title: window.env.title,
    exposedApiUrl: window.env.exposedApiUrl,
    exclusiveApiUrl: window.env.exclusiveApiUrl,
    protectedApiUrl: window.env.protectedApiUrl,
    authorizationApiUrl: window.env.authorizationApiUrl
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
