<h3 mat-dialog-title>
	<span class="truncate">Edit {{data.type}} for {{data.username}}</span>
	<span class="dialog-close" (click)="onNoClick()">&#10006;</span>
</h3>

<div mat-dialog-content>
	<mat-list class="unassigned">
		<h2 matSubheader>Available {{data.type}}</h2>
		<mat-button-toggle-group name="fontStyle" aria-label="Font Style">
			<mat-button-toggle (click)="moveAllRows(2)">
				<mat-icon style="width: 7px">chevron_right</mat-icon>
				<mat-icon>chevron_right</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle (click)="moveOneRow(2)">
				<mat-icon>chevron_right</mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>
		<cdk-virtual-scroll-viewport itemSize="10" class="unassignedList">
			<mat-list-item matSort *ngFor="let item of data.unassignedList" (click)="updateSelectedItems(item, 2 )"
						   [ngClass]="{'selected' : item.selected}"> {{item.display ? item.display : item.name}}</mat-list-item>
		</cdk-virtual-scroll-viewport>
	</mat-list>
	<mat-list>
		<h2 matSubheader>Assigned {{data.type}}</h2>
		<mat-button-toggle-group name="fontStyle" aria-label="Font Style">
			<mat-button-toggle (click)="moveOneRow(1)">
				<mat-icon>chevron_left</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle (click)="moveAllRows(1)">
				<mat-icon style="width: 7px">chevron_left</mat-icon>
				<mat-icon>chevron_left</mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>
		<cdk-virtual-scroll-viewport itemSize="10">
			<mat-list-item matSort *ngFor="let item of data.assignedList" (click)="updateSelectedItems(item, 1)"
						   [ngClass]="{'selected' : item.selected}"> {{item.display ? item.display : item.name}} </mat-list-item>
		</cdk-virtual-scroll-viewport>
	</mat-list>
</div>
<div mat-dialog-actions class="dialog-action">
	<button mat-button (click)="onNoClick()">Cancel</button>
	<button mat-raised-button [mat-dialog-close]="data.assignedList" class="dialog-submit-btn" color="accent">Update</button>
</div>
