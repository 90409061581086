<div class="files-list">
    <div *ngFor="let file of files; let i = index"
         (click)="loadInViewer(i)" [class]="setDocumentSelectedCss(i)">
        {{i + 1}}. {{file.name}}
    </div>
    <app-file-viewer (goToNext)="loadInViewer($event)"
                     (goToPrevious)="loadInViewer($event)"
                     [currIndex]="currentInViewerIndex" [total]="files.length"
                     [idContainer]="'viewer' + currentInViewerIndex" *ngIf="files.length > 0"
                     class="file-viewer">
    </app-file-viewer>
</div>

