import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class PublicRouteGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const isPublicRoute = next.data.isPublicRoute as boolean;

        if (isPublicRoute && this.authService.isLoggedIn()) {
            let isFullScreenMode = null;
            const redirectTo = '/auth/dashboard';
            if (next.paramMap.has('mode')) {
                isFullScreenMode = next.paramMap.get('mode');
            }

            this.authService.isFullScreenMode$.next(isFullScreenMode ? true : null);
            this.router.navigate([redirectTo]);
            return false;
        }
        return true;
    }
}
