<div id="{{idContainer}}" class="file-viewer-card" #fileViewerContainer>
    <div id="image-gallery" class="file-viewer">
        <div class="image-container" [hidden]="hide">
            <div class="pdf-container">
                <ng2-pdfjs-viewer #pdfViewer [showSpinner]="true" [externalWindow]="isExternal"></ng2-pdfjs-viewer>
            </div>
            <div class="footer-info">
                <i class="material-icons prev" (click)="previous()">keyboard_arrow_left</i>
                <span class="current">{{currIndex + 1}}</span><span>/</span><span class="total">{{total}}</span>
                <i class="material-icons next" (click)="next()">keyboard_arrow_right</i>
            </div>
        </div>
    </div>
</div>
