import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input, OnDestroy, OnInit,
    Output, ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {FileService} from '../../../services/file.service';

@Component({
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FileViewerComponent implements OnInit, OnDestroy {
    // @ts-ignore
    @ViewChild('pdfViewer', {static: true}) pdfViewer;
    @ViewChild('fileViewerContainer', {static: false}) private fileViewerContainer: ElementRef;
    @Input() total: number;
    @Input() currIndex: number;
    @Input() idContainer: string;
    @Input() isExternal: boolean;

    @Output() goToNext = new EventEmitter();
    @Output() goToPrevious = new EventEmitter();

    subscription: Subscription;
    hideImage = true;
    hidePdf = true;
    hide = true;
    currFile: File;

    constructor(private fileService: FileService,
                private cdRef: ChangeDetectorRef)
    { }

    ngOnInit() {
        this.hide = true;
        this.hideImage = true;
        this.hidePdf = true;
        this.pdfViewer.pdfSrc = undefined;

        this.subscription = this.fileService.selectedFileForViewer$.subscribe(
            file => {
                if (file) {
                    this.currFile = file;
                    if (this.isPDF()) {
                        this.hidePdf = false;
                    }
                    this.hide = false;
                }
            }
        );
        this.subscription.add(this.fileService.currentFileInViewerBytes$.subscribe(fileBytes => {
            if (!fileBytes) {
                return;
            }
            this.pdfViewer.pdfSrc = fileBytes;
            this.pdfViewer.refresh();
            this.cdRef.detectChanges();
        }));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.fileService.reset();
    }

    previous() {
        this.goToPrevious.emit(this.currIndex - 1);
    }

    next() {
        this.goToNext.emit(this.currIndex + 1);
    }

    isPDF() {
        return this.currFile && (this.currFile.type === 'application/pdf' && this.pdfViewer.pdfSrc !== null);
    }
}
