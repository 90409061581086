export enum SubmissionDefinition
{
    Loaded = 1,
    PendingAssignment = 2,
    Assigned = 3,
    Working = 4,
    OnHold = 5,
    DateDown = 6,
    PendingSubmission = 7,
    Submitted = 8,
    Completed = 9,
    RejectedByCounty = 10,
    RejectedByUser = 11,
    Error = 12,
}
