/* tslint:disable:variable-name */
/* eslint-disable no-underscore-dangle */
import {Component, Input, OnInit} from '@angular/core';
import {GetSubmissionResponse} from '../../../models/interfaces/get-submission-response';
import {FileViewerUtilService} from '../../../services/file-viewer-util.service';
import {FileMeta} from '../../../models/file-meta';
import {SubmissionService} from '../../../services/submission.service';
import {FileType} from '../../../models/enums/file-type';

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
    @Input() fileType: FileType;
    @Input() refreshInitialTabFile: string;

    @Input()
    set files(files: FileMeta[]) {
        this._files = files;
    }

    get files(): FileMeta[] {
        return this._files;
    }

    @Input() set fileParent(submission: GetSubmissionResponse) {
        this._fileParent = submission;
        if (this._fileParent && this.files.length > 0) {
            this.loadInViewer(0);
        }
    }

    currentInViewerIndex: number;
    private _fileParent: GetSubmissionResponse;
    private _files: FileMeta[];

    constructor(private fileViewerUtilService: FileViewerUtilService,
                private submissionService: SubmissionService) {
    }

    ngOnInit(): void {
    }

    setDocumentSelectedCss(index: number): string {
        const general = 'doc ';
        return this.currentInViewerIndex === index
            ? general + 'doc-selected'
            : general + 'doc-not-selected';
    }

    loadInViewer(index: number): void {
        if (this._files.length === 0) {
            return;
        }
        index = index > this._files.length - 1
            ? 0
            : index < 0 ? this._files.length - 1 : index;
        this.getFile(index);
    }

    private getFile(fileIndex: number): void {
        if (this._fileParent) {
            this.currentInViewerIndex = fileIndex;
            this.fileViewerUtilService.getFile(this._files[fileIndex], this.fileType);
        }
    }
}
