import { Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FileMeta} from '../models/file-meta';
import {HttpClient} from '@angular/common/http';
import {config} from '../app.config';
import {FileType} from '../models/enums/file-type';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    currentFileInViewerBytes$ = new BehaviorSubject<any>(null);
    selectedFileForViewer$ = new BehaviorSubject<FileMeta>(null);
    private readonly documentController: string = 'document/';
    private readonly recordedDocumentController: string = 'recordeddocument/';

    constructor(private http: HttpClient) {
    }

    getBytes(id: string, fileType: FileType): Observable<any> {
        let controller = this.documentController;
        switch (fileType) {
            case FileType.Document:
                controller = this.documentController;
                break;
            case FileType.RecordedDocument:
                controller = this.recordedDocumentController;
                break;
        }
        return this.http.get(config.exclusiveApiUrl + controller + `getbytes?id=${id}`);
    }

    reset() {
        this.currentFileInViewerBytes$.next(null);
        this.selectedFileForViewer$.next(null);
    }
}

