import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SpinnerService} from './services/spinner.service';
import {BaseComponent} from './shared/components/base.component';
import {delay, startWith, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit {
    title = 'EtInvest.Recording.Web';
    showSpinner: boolean;

    constructor(public spinnerService: SpinnerService) {
        super();
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.spinnerService.visibility
            .pipe(
                takeUntil(this.ngUnsubscribe),
                startWith(false),
                delay(0))
            .subscribe(val => {
                this.showSpinner = val;
            });
    }
}
